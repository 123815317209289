<template>
  <div class="container mescroll-touch" id="toTop">
    <mescroll-vue ref="mescroll" :down="mescrollDown" :up="mescrollUp" @init="mescrollInit">
      <!-- <div class="banner">
        <img class="bannerImg" v-if="code == 'jnpx|jnpx_jnkt|'" src="https://fx.tv-conf.com/Api/UploadImg/SkillTraining_Img/Img_jnkt.png" />
        <img class="bannerImg" v-if="code == 'jnpx|jnpx_ydsd|'" src="https://fx.tv-conf.com/Api/UploadImg/SkillTraining_Img/Img_ydsb.png" />
        <img class="bannerImg" v-if="code == 'jnpx|jnpx_qwkt|'" src="https://fx.tv-conf.com/Api/UploadImg/SkillTraining_Img/Img_qwkt.png" />
        <img class="goBack" src="@/assets/images/back_comm_icon@2x.png" @click="$router.go(-1)" />
      </div> -->
      <div class="banner" v-if="bannerList.length > 0">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#CA001B">
          <van-swipe-item v-for="(item, index) in bannerList" :key="index" @click="imgclick(item.UrlLink)">
            <img class="bannerImg" :src="item.File_Url" />
          </van-swipe-item>
        </van-swipe>
        <img class="goBack" src="@/assets/images/back_comm_icon@2x.png" @click="$router.go(-1)" />
      </div>
      <div class="classify">
        <div class="home_classify_item" :class="{'home_classify_item_active': ModuleType == 0}" @click="selectClassify(0)">
          <img class="screenIcon" :src="ModuleType == 0 ? require('@/assets/images/new_home_icon_alt@2x.png') : require('@/assets/images/new_home_icon_nor@2x.png')" />
          <span>最新上线</span>
        </div>
        <!-- <div class="home_classify_item" :class="{'home_classify_item_active': ModuleType == 2}" @click="selectClassify(2)">
          <img class="screenIcon" :src="ModuleType == 2 ? require('@/assets/images/good_home_icon_alt@2x.png') : require('@/assets/images/good_home_icon_nor@2x.png')" />
          <span>热门推荐</span>
        </div> -->
      </div>
      <div id="dataList">
        <waterfall :col="2" :width="itemWidth" :gutterWidth="gutterWidth" :data="list">
          <template>
            <div class="list" v-for="(item, index) in list" :key="index" @click="toContentPage(item.Id, item.Type)">
              <div class="item_img">
                <img class="item_cover" :src="item.Thumbnail" />
                <img class="playIcon" v-if="item.Type == 1 || item.Type == 3" src="@/assets/images/play_comm_icon@2x.png" />
              </div>
              <div class="item_content">
                <span class="item_title">{{item.Title}}</span>
                <p class="item_time">{{item.yyyymmdd}}</p>
                <div class="item_nums">
                  <div class="nums">
                    <img src="@/assets/images/browse_comm_icon@2x.png" />
                    <span>{{item.WatchCount}}</span>
                  </div>
                  <div class="nums">
                    <img src="@/assets/images/like_comm_icon@2x.png" />
                    <span>{{item.LikeCount}}</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </waterfall>
      </div>
    </mescroll-vue>
  </div>
</template>

<script>
import MescrollVue from 'mescroll.js/mescroll.vue'

export default {
  name: 'SkillTrainingList',
  data() {
    return {
      bannerList: [],
      ModuleType: 0,
      list: [],
      PageIndex: 0,
      PageSize: 6,
      mescroll: null, // mescroll实例对象
      mescrollDown:{
        use: false,
      },
      mescrollUp: { // 上拉加载的配置.
        isBounce: false,
			  callback: this.upCallback, // 上拉回调
				page: {
					num: 0, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
					size: 6 //每页数据条数,默认10
				},
        noMoreSize: 0,
				htmlNodata: '<p class="upwarp-nodata">-- END --</p>',
        toTop: {
          warpId : 'toTop',
          src: './static/image/top_comm_icon@2x.png' // 回到顶部按钮的图片路径,支持网络图
        },
        empty: {
          // 列表第一页无任何数据时,显示的空提示布局; 需配置warpId才生效;
          warpId: 'dataList', // 父布局的id;
          icon: './static/image/none_comm_png@2x.png', // 图标,支持网络图
          tip: '敬请期待', // 提示
        },
			},
    }
  },
  components: {
    MescrollVue
  },
  computed: {
    code() {
      return this.$route.query.code;
    },
    itemWidth() {
      return (340 * 0.5 * (document.documentElement.clientWidth / 375))
    },
    gutterWidth() {
      return (20 * 0.5 * (document.documentElement.clientWidth / 375))
    }
  },
  activated(){
    this.mescroll.setBounce(false);
    this.mescroll.scrollTo(this.mescroll.preScrollY, 0);
  },
  deactivated() {
    this.mescroll.setBounce(true);
  },
  created() {
    this.$store.commit('changeNavbarIndex', 1);
    this.getBanner();
  },
  methods: {
    // 获取Banner
    getBanner() {
      let arr = this.code.split('|');
      let code = arr[arr.length - 2];
      this.$axios.get('/Api/api/Web/ImgFile/GetImgFileListByCode?Code=' + code).then(res => {
        this.bannerList = res.Data;
      })
    },
    // mescroll组件初始化的回调,可获取到mescroll对象
		mescrollInit (mescroll) {
			this.mescroll = mescroll  // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
		},
    // 上拉回调
		upCallback (page) {
      this.getList(page.num, page.size);
		},
    selectClassify(ModuleType) {
      this.ModuleType = ModuleType;
      this.list = [];
      this.mescroll.resetUpScroll() // 刷新列表数据
    },
    // 获取列表数据
    getList(PageIndex, PageSize) {
      this.PageIndex = PageIndex;
      this.PageSize = PageSize;
      setTimeout(() => {
        this.$axios.post('/Api/Api/Web/Article/GetArticleList', {
          SubjectTCode: this.code,
          ModuleType: this.ModuleType,
          PageIndex: this.PageIndex,
          PageSize: this.PageSize
        }).then((res) => {
          let arr = res.Data.List;
          // 如果是第一页需手动置空列表
          if (PageIndex === 1) this.list = []
          // 把请求到的数据添加到列表
          this.list = this.list.concat(arr);
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            this.mescroll.endSuccess(arr.length)
          })
        }).catch((e) => {
          // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
          this.mescroll.endErr()
        })
      }, 200);
    },
    toContentPage(Id, Type) {
      this.$router.push({
        path: 'contentPage',
        query: {
          id: Id,
          type: Type
        }
      });
    },
    imgclick(UrlLink) {
      let objExp=new RegExp(/^(f|ht)tps?:\/\//i);
      if(UrlLink) {
				if(objExp.test(UrlLink)) {
				  location.href = UrlLink;
				}else{
					this.$router.push(UrlLink)
				}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  background-color: #F9F9F9;
  .mescroll {
    position: fixed;
    top: 0;
    bottom: 60px;
    height: auto;
    padding: 15px 12px;
    box-sizing: border-box;
    // .banner {
    //   position: relative;
    //   border-radius: 10px;
    //   margin-bottom: 15px;
    //   overflow: hidden;
    //   .bannerImg {
    //     display: block;
    //     width: 100%;
    //   }
    //   .goBack {
    //     position: absolute;
    //     top: 10px;
    //     left: 10px;
    //     width: 30px;
    //     height: 30px;
    //   }
    // }
    .banner {
      position: relative;
      border-radius: 10px;
      margin-bottom: 15px;
      overflow: hidden;
      .bannerImg {
        width: 100%;
        display: block;
      }
      .goBack {
        position: absolute;
        top: 10px;
        left: 10px;
        width: 30px;
        height: 30px;
      }
    }
    .classify {
      display: flex;
      margin-bottom: 10px;
      .home_classify_item {
        display: flex;
        align-items: center;
        height: 30px;
        border-radius: 30px;
        padding: 0 10px;
        margin-right: 10px;
        box-sizing: border-box;
        font-size: 12px;
        color: #CA001B;
        border:  1px solid rgba(202, 0, 27, 0.5);
        .screenIcon {
          width: 16px;
          height: 14px;
          margin-right: 4px;
        }
      }
      .home_classify_item_active {
        color: #FFFFFF;
        background-color: #CA001B;
        border: none;
      }
    }
    .list {
      width: 100%;
      background-color: #FFFFFF;
      border-radius: 4px;
      overflow: hidden;
      margin-bottom: 10px;
      .item_img {
        position: relative;
        .item_cover {
          display: block;
          width: 100%;
        }
        .playIcon {
          position: absolute;
          right: 12px;
          bottom: 12px;
          width: 30px;
          height: 30px;
        }
      }
      .item_content {
        padding: 5px 8px 8px;
        .item_title {
          height: 38px;
          font-size: 14px;
          color: #333333;
          overflow: hidden;
          text-overflow:ellipsis;
          display:-webkit-box;
          -webkit-box-orient:vertical;
          -webkit-line-clamp:2;
          word-wrap: break-word;
        }
        .item_time {
          font-size: 12px;
          color: #999999;
          line-height: 24px;
        }
        .item_label {
          display: flex;
          flex-wrap: wrap;
          span {
            display: inline-block;
            height: 20px;
            line-height: 20px;
            border-radius: 2px;
            padding: 0 6px;
            margin-right: 6px;
            margin-bottom: 5px;
            background-color: rgba(255, 73, 98, 0.15);
            font-size: 11px;
            color: #CA001B;
          }
        }
        .item_nums {
          display: flex;
          justify-content: flex-end;
          .nums {
            display: flex;
            align-items: center;
            img {
              width: 12px;
              margin: 0 2px 0 6px;
            }
            span {
              font-size: 11px;
              color: #666666;
            }
          }
        }
      }
    }
  }
}
</style>
